.Negociations {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}


.Negociations .main {
    width: 100%;
    margin-top: 80px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.Negociations .main .text {
    width: 80%;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin: 10px;
}

.Negociations .main .text h2 {
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}

.Negociations .main .text h3 {
    font-weight: 700;
    color: var(--Paragraph);
    text-align: center;
}
.Negociations .main .text h3 svg{
    font-size: 45px;
    font-weight: 900;
    color: var(--Primary)
}
.Negociations .main .text a{
    border-radius: 6px;
    background-color: var(--Primary);
    color: var(--White);
    padding: 10px 20px;
    text-decoration: none;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 20px 0;
}
.Negociations .main .text .chat{
    width: 550px;
    border-radius: 10px;
    background-color: var(--BorderInput);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 10px;
    height: 100px;
}
.Negociations .main .text .chat .image{
    border-radius: 10px 0 0 10px;
    width: 80px;
    height: 100%;
    object-fit: cover;
    border-right: 3px solid var(--White);
}
.Negociations .main .text .chat .image img{
    border-radius: 10px 0 0 10px;
    width: 80px;
    height: 100%;
    object-fit: cover;
}
.Negociations .main .text .chat .image svg{
    font-size: 70px;
    color: var(--Primary);
}
.Negociations .main .text .chat .image h3{
    color: var(--White);
        position: absolute;
        margin-top: 2px;
        font-size: 16px;
}
.Negociations .main .text .chat .image h4{
    color: var(--White);
        position: absolute;
        margin-top: 35px;
        font-size: 18px;
        font-weight: 900;
}
.Negociations .main .text .chat .textChat{
    border-radius: 10px;
    background-color: var(--BorderInput);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 0 5px;
    text-align:left;
}
.Negociations .main .text .chat .textChat h4 {
    color: var(--Primary);
    font-weight: 700;
    margin: 0 10px;
    text-align:left;
  }

  .Negociations .main .text .chat .textChat h5 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    color: var(--Gray2);
    margin: 3px 8px;
    text-align:left;
    font-weight: 700;
  }
  .Negociations .main .text .chat .textChat h6 {
    color: var(--Gray);
    margin: 0 7px 0 7px;
    text-align:left;
    font-weight: 700;
  }
  .Negociations .main .text .chat .textChat h6 svg {
    margin-right: 2px;
  }

@media (max-width: 800px) {
    .Negociations .main .text .chat{
        width: 500px;
        margin: 5px;
    }

    @media (max-width: 700px) {
        .Negociations .main {
            margin-top: 50px;
            margin-bottom: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }

        .Negociations .main .text {
            width: 100%;
            padding: 0px;
        }

        @media (max-width: 700px) {
            .Negociations .main .text .chat{
                width: 98%;
            }
        }
        
    }
}

