.buttonCounter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;  
}

.iconUnic {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    padding: 10px;
    background-color: rgba(238,238,238,0.5);
    font-weight:600;
    color: var(--Primary);
    font-size:18px;
    margin:5px 0px;
}

.couterChat {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    padding: 10px;
    background-color: var(--Primary);
    font-weight:600;
    color: var(--White);
    font-size:10px;
    width: 20px;
    height: 20px;
    margin-top: -25px;
    margin-right: -15px;
    z-index: 10;
}