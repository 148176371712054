.Contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0px 0px 0px;
}

.Contact h2 {
    margin-top: 40px;
    font-weight: 700;
    color: var(--Primary);
    text-align: center;
}

.Contact h2 span {
    color: var(--Primary);
}


.Contact h4 {
    color: var(--Paragraph);
    margin:10px;
    text-align: center;
}


.Contact .form {
        width: 550px;
        text-align: center;
        align-itens: center;
        justify-content: center;
        flex-direction: column;
        display: flex;
        margin: 20px 0px;
}

.Contact .form .inputsContact {
    width: 100%;
    align-itens: center;
    justify-content: center;
    flex-direction: row;
}

.Contact .form .inputsContact input {
    border: 1px solid var(--Border);
    background-color: var(--BorderHover);
    width: 265px;
    margin: 5px;
    padding: 10px 20px;
    border-radius: 20px;
}
.Contact .form select {
    border: 1px solid var(--Border);
    background-color: var(--BorderHover);
    width: 98%;
    margin: 5px;
    padding: 10px 20px;
    border-radius: 20px;
}
.Contact .form textarea {
    border: 1px solid var(--Border);
    background-color: var(--BorderHover);
    width: 98%;
    margin: 5px;
    padding: 10px 20px;
    border-radius: 20px;
    resize: none;
}
.Contact .form button {
    border: none;
    background-color: var(--Primary);
    color: var(--White);
    font-weight: 700;
    width: 98%;
    margin: 5px;
    padding: 10px 20px;
    border-radius: 20px;
}


.Contact .BlocksContact {
    width: 1000px;
    display: flex;
    text-align: center;
    align-itens: center;
    justify-content: space-around;
    flex-direction: row;
    padding: 20px;
    margin: 20px 0;
    flex-wrap: wrap;
}

.Contact .BlocksContact .blockContact {
    width: 300px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-itens: center;
    justify-content: flex-start;
    background-color: var(--BorderHover);
    padding: 20px;
    border-radius: 20px;
    margin: 10px;
}
.Contact .BlocksContact .blockContact:hover {
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    background-color: var(--White);
}

.Contact .BlocksContact .blockContact h3 {
    display: flex;
    flex-direction: row;
    align-itens: flex-end;
    justify-content: center;
    font-weight: 700;
    color: var(--Paragraph);;
    text-align: center;
}

.Contact .BlocksContact .blockContact h3 svg {
    margin-right: 5px;
    margin-top: -3px;
    color: var(--Primary);
    font-size: 24px;
}

.Contact .BlocksContact .blockContact h5 {
    display: flex;
    flex-direction: row;
    align-itens: center;
    justify-content: center;
    font-weight: 400;
    color: var(--Gray);
    text-align: center;
    margin: 5px 0;
}

.Contact .BlocksContact .blockContact button {
    display: flex;
    flex-direction: row;
    align-itens: center;
    justify-content: center;
    border: none;
    background-color: var(--Primary);
    color: var(--White);
    font-weight: 700;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 20px;
}
.Contact .BlocksContact .blockContact button:hover {
    background-color: var(--White);
    color: var(--Primary);
}

.Contact .map {
    width: 90%;
    border-radius: 20px;
    margin-bottom: 40px;
}

@media (max-width: 1000px) {
    .Contact .BlocksContact {
        width: 100%;
        justify-content: center;
    }

    @media (max-width: 600px) {
        .Contact .form {
            width: 100%;
            padding: 20px;
    }
    
    .Contact .form .inputsContact {
        width: 100%;
        align-itens: center;
        justify-content: center;
        flex-direction: column;
    }
    
    .Contact .form .inputsContact input {
        width: 98%;
    }
    }
}