.NewSearchClient {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 1120px;
    height: 400px;
    margin: 40px auto;
}

.NewSearchClient .imageClient {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 400px;
    object-fit: cover;
    border-radius: 20px 0 0 20px;
}

.NewSearchClient .imageClient img {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 20px 0 0 20px;
}

.NewSearchClient .txtSearch {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    height: 400px;
    background-color: var(--Primary);
    color: var(--White);
    border-radius: 0  20px 20px 0;
    padding: 100px;
}

.NewSearchClient .txtSearch h1 {
    font-weight: 800;
}

.NewSearchClient .txtSearch h4 {
    margin: 30px 0;
}


.NewSearchClient button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    border-radius: 25px;
    padding: 15px 20px;
    border: none;
    color: var(--White);
    background-color: var(--ButtonDisabled);
}

.NewSearchClient button svg {
    margin-right: 5px;
    font-size: 16px;
    transform: rotate(-25deg);
}



.content-modal-Searching {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    background-color: var(--White);
}
.content-modal-Searching .itensModalSearching {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow:auto; 
    max-height: 500px;
    margin: 20px;
    padding: 20px;
}

.content-modal-Searching .itensModalSearching::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
  .content-modal-Searching .itensModalSearching::-webkit-scrollbar-track {
    background: var(--BorderHover);        /* color of the tracking area */
  }
  
  .content-modal-Searching .itensModalSearching::-webkit-scrollbar-thumb {
    background-color: var(--Border);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid var(--BorderHover);  /* creates padding around scroll thumb */
  }

.content-modal-Searching .itensModalSearching svg {
    color: var(--Primary);
    font-size: 45px;
}
.content-modal-Searching .itensModalSearching h2 {
    font-weight: 800;
    color: var(--Paragraph);
    margin: 5px 0px 15px 0px;
}
.content-modal-Searching .itensModalSearching h6 {
    font-weight: 700;
    color: var(--Paragraph);
    margin: 5px;
}
.content-modal-Searching .itensModalSearching h4 {
    margin-right: 30px;
}

.content-modal-Searching .itensModalSearching a {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    background-color: var(--Primary);
    color: var(--White);
    margin: 30px 0 10px 0px;
    font-weight: 700;
    text-decoration: none;
    font-size: 14px;
}

.content-modal-Searching .itensModalSearching .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.content-modal-Searching .itensModalSearching .form  .dataItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 5px;
}
.content-modal-Searching .itensModalSearching .form  .dataItem .dataItemSearch{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin:0px 5px;
}

.content-modal-Searching .itensModalSearching .form  .dataItem .dataItemSearch h6 {
    font-weight: 700;
    margin: 3px 15px;
    color: var(--Gray2);
}
.content-modal-Searching .itensModalSearching .form  .dataItem .dataItemSearch input {
    width: 100%;
    padding: 5px 15px;
    border: 1px solid var(--Border);
    border-radius: 25px;
    font-weight: 600;
    color: var(--Paragraph);
    font-size: 12px;
}

.content-modal-Searching .itensModalSearching .form  .dataItem .dataItemSearch input::placeholder {
    color: var(--Gray); 
}
.content-modal-Searching .itensModalSearching .form  .dataItem .dataItemSearch select {
    width: 100%;
    padding: 5px 10px;
    border: 1px solid var(--Border);
    border-radius: 25px;
    font-weight: 600;
    color: var(--Gray);
    font-size: 12px;
}
.content-modal-Searching .itensModalSearching .form  .dataItem .dataItemSearch .select {
    width: 100%;
    padding: 5px 10px;
    border: 1px solid var(--Paragraph);
    border-radius: 25px;
    font-weight: 600;
    color: var(--Paragraph);
    font-size: 12px;
}

.content-modal-Searching .itensModalSearching button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border-radius: 25px;
    padding: 10px 20px;
    color: var(--White);
    cursor: pointer;
    margin: 20px 5px 10px 5px;
    border: 1px solid var(--Primary);
}
.content-modal-Searching .itensModalSearching button svg {
    margin-right: 10px;
}
.content-modal-Searching .itensModalSearching button:hover {
    background-color: var(--White);
    color: var(--Primary);
    border: 1px solid var(--Primary);
}



@media (max-width: 1130px) {

    .NewSearchClient {
        width: 90%;
    }

    @media (max-width: 1000px) {
    
    .NewSearchClient .txtSearch {
        padding: 50px;
    }


    @media (max-width: 750px) {
    .NewSearchClient {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: 600px;    
        margin: 40px auto;
    }

    .NewSearchClient .imageClient {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 6px 6px 0 0;
    }
    
    .NewSearchClient .imageClient img {
        height: 300px;
        object-fit: cover;
        border-radius: 6px 6px 0 0;
    }
    
    .NewSearchClient .txtSearch {
        width: 100%;
        height: 300px;
        border-radius:   0 0 6px 6px;
    }

    .NewSearchClient .txtSearch h4 {
        margin: 10px 0;
    }

    @media(max-width: 450px) {
        .NewSearchClient .txtSearch {
            width: 100%;
            height: 400px;
            border-radius:   0 0 6px 6px;
            padding: 20px;
        }
        
        .content-modal-Searching .itensModalSearching .form  .dataItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 5px;
        }
    }
    
}
}
}