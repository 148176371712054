.App2 {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
}

.App2 h2 {
    position: relative;
    overflow: hidden;
    color: #fff;
    font-weight: 900;
    font-size: 45px;
}

.App2 h2:before {
    content: " ";
    position: absolute;
    left: 120%;
    width: 120%;
    height: 100%;
    background: linear-gradient(90deg, transparent 0%, #000 5%, #000 100%);
    animation: animate 10s linear forwards; 
}

@keyframes animate {
    0% {
        left: -20%;
    }
    50% {
        left: 120%;
    }
    10% {
        left: -20%;
    }
}

