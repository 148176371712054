.PropertyUnicBlock {
    width: 250px;
    height: 235px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--White);
    /* border: 1px solid var(--Border); */
    border-radius: 15px;
    margin: 20px auto;
    padding-bottom: 5px;
}



.PropertyUnicBlock .imageSlider {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 130px;
      border-radius: 6px;
    background-color: var(--BorderInput2);
    object-fit: cover;
}

.PropertyUnicBlock .imageSlider a {
    width: 100%;
    color: var(--Primary);
    text-decoration: none;
  }
  
  
  .PropertyUnicBlock .imageSlider a img {
    width: 100%;
    height: 130px;
    object-fit: cover;
      border-radius: 10px;
  }
  

.PropertyUnicBlock .featured {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border-radius: 20px;
    position: absolute;
    margin-top: -15px;
    margin-left: 10px;
    padding: 10px 40px;
}

.PropertyUnicBlock .featured p {
    color: var(--White);
    font-weight: 700;
}



.PropertyUnicBlock .infosPropertyButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 5px;
    margin-top: -40px;
    z-index:10;
}

.PropertyUnicBlock .infosPropertyButtons .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(224, 40, 47, 0.6);
    border-radius: 20px;
    padding: 7px;
}

.PropertyUnicBlock .infosPropertyButtons .status a {
    color: var(--White);
    font-weight: 700;
    text-decoration: none;
}
.PropertyUnicBlock .infosPropertyButtons .status p {
    color: var(--White);
    font-weight: 700;
}
.PropertyUnicBlock .infosPropertyButtons .status .image {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    object-fit: contain;
    background-color: var(--White);
    margin-right: 3px;
}
.PropertyUnicBlock .infosPropertyButtons .status .image img {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    object-fit: contain;
}

.PropertyUnicBlock .infosPropertyButtons .infosButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 25%;
    margin-top: -180px;
}

.PropertyUnicBlock .infosPropertyButtons .infosButtons .heart2 {

}

.PropertyUnicBlock .infosPropertyButtons .infosButtons .heart2 svg {
    color: var(--Primary);
    font-size: 24px;
    cursor: pointer;
}
.PropertyUnicBlock .infosPropertyButtons .infosButtons .share {
margin-top: 170px;
}

.PropertyUnicBlock .infosPropertyButtons .infosButtons .share svg {
    color: var(--Border);
    font-size: 20px;
    cursor: pointer;
}





.PropertyUnicBlock .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.PropertyUnicBlock .text .textInfos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.PropertyUnicBlock .text .textInfos h2 {
    color: var(--Paragraph);
    font-weight: 700;
    margin: 20px 5px 5px 5px;
}
.PropertyUnicBlock .text .textInfos h3 {
    color: var(--Paragraph);
    font-weight: 700;
    margin: 10px 5px 5px 5px;
}

.PropertyUnicBlock .text .textInfos a {
    text-decoration: none;
    
}
.PropertyUnicBlock .text .textInfos h4 {
    color: var(--Paragraph);
    font-weight: 700;
    margin: 0px 5px 0px 5px;
}
.PropertyUnicBlock .text .textInfos h5 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--Gray);
    margin: 2px 5px;
    font-weight: 500;
}
.PropertyUnicBlock .text .textInfos h6 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--Gray);
    margin: 15px 5px 0px 5px; 
    font-weight: 600;
}
.PropertyUnicBlock .text .iconsBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 7px 5px 5px 5px; 
    padding: 0px;
    flex-wrap: wrap;

}

.PropertyUnicBlock .text .iconsBox .iconUnicBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: 0px;
    color: var(--Gray);
}
.PropertyUnicBlock .text .iconsBox .iconUnicBox svg {
    font-size: 18px;
    margin-right: 5px;
    color: var(--Paragraph);
    margin-top: -2px;

}

.PropertyUnicBlock .text .iconsBox .iconUnicBox .simbolBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: center;
    margin-top: 1px;
}
.PropertyUnicBlock .text .iconsBox .iconUnicBox .simbolBox  p {
    color: var(--Gray);
    font-weight: 600;
    font-size: 10px;
}

.PropertyUnicBlock .text .pricing {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: flex-start;
    margin-top: 5px;
}

.PropertyUnicBlock .text .pricing h6 {
    color: var(--Gray);
    font-weight: 800;
    margin: 0 5px;
    text-align: flex-start;
}
.PropertyUnicBlock .text .pricing h6 span {
    font-weight: 500;
    color: var(--Gray);
}
.PropertyUnicBlock .text .pricing h3 {
    color: var(--Paragraph);
    font-weight: 400;
    margin: 2px 5px 2px 5px;
    text-align: flex-end;
        width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.PropertyUnicBlock .text .pricing span {
    color: var(--Paragraph);
    font-weight: 800;
}
.PropertyUnicBlock .text .pricing h4 {
    color: var(--Paragraph);
    font-weight: 400;
    margin: 0px 0px 0px 5px;
    text-align: flex-end;
        width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.PropertyUnicBlock .text .pricing h4 span {
    color: var(--Paragraph);
    font-weight: 800;
}



.PropertyUnicEmphasis {
    width: 250px;
    height: 235px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--PrimaryEnphasis);
    /* border: 1px solid var(--Border); */
    border-radius: 15px;
    margin: 20px auto;
    padding-bottom: 5px;
}



.PropertyUnicEmphasis .imageSliderEmphasis {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 130px;
      border-radius: 6px;
    background-color: var(--BorderInput2);
    object-fit: cover;
}

.PropertyUnicEmphasis .imageSliderEmphasis a {
    width: 100%;
    color: var(--Primary);
    text-decoration: none;
  }
  
  
  .PropertyUnicEmphasis .imageSliderEmphasis a img {
    width: 100%;
    height: 130px;
    object-fit: cover;
      border-radius: 10px;
      
  }

  
  

.PropertyUnicEmphasis .featured {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border-radius: 20px;
    position: absolute;
    margin-top: -15px;
    margin-left: 10px;
    padding: 10px 40px;
}

.PropertyUnicEmphasis .featured p {
    color: var(--White);
    font-weight: 700;
}



.PropertyUnicEmphasis .infosPropertyButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 5px;
    margin-top: -40px;
    z-index:10;
}

.PropertyUnicEmphasis .infosPropertyButtons .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(224, 40, 47, 0.6);
    border-radius: 20px;
    padding: 7px;
}

.PropertyUnicEmphasis .infosPropertyButtons .status a {
    color: var(--White);
    font-weight: 700;
    text-decoration: none;
}
.PropertyUnicEmphasis .infosPropertyButtons .status p {
    color: var(--White);
    font-weight: 700;
}
.PropertyUnicEmphasis .infosPropertyButtons .status .image {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    object-fit: contain;
    background-color: var(--White);
    margin-right: 3px;
}
.PropertyUnicEmphasis .infosPropertyButtons .status .image img {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    object-fit: contain;
}

.PropertyUnicEmphasis .infosPropertyButtons .infosButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 25%;
}

.PropertyUnicEmphasis .infosPropertyButtons .infosButtons .heart3 {
    background-color: var(--White);
    padding: 5px;
    width: 100%;
}

.PropertyUnicEmphasis .infosPropertyButtons .infosButtons .heart3 svg {
    color: var(--Primary);
    font-size: 24px;
    cursor: pointer;
}
.PropertyUnicEmphasis .infosPropertyButtons .infosButtons .share {
margin-top: -20px;
}

.PropertyUnicEmphasis .infosPropertyButtons .infosButtons .share svg {
    color: var(--Border);
    font-size: 20px;
    cursor: pointer;
}





.PropertyUnicEmphasis .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.PropertyUnicEmphasis .text .textInfos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.PropertyUnicEmphasis .text .textInfos h2 {
    color: var(--Paragraph);
    font-weight: 700;
    margin: 20px 10px 5px 10px;
}
.PropertyUnicEmphasis .text .textInfos h3 {
    color: var(--Paragraph);
    font-weight: 700;
    margin: 10px 10px 5px 10px;
}

.PropertyUnicEmphasis .text .textInfos a {
    text-decoration: none;
    
}
.PropertyUnicEmphasis .text .textInfos h4 {
    color: var(--Paragraph);
    font-weight: 700;
    margin: 0px 5px 0px 5px;
}
.PropertyUnicEmphasis .text .textInfos h5 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--Gray);
    margin: 2px 9px;
    font-weight: 500;
}
.PropertyUnicEmphasis .text .textInfos h6 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--Gray);
    margin: 15px 5px 0px 5px; 
    font-weight: 600;
}


.PropertyUnicEmphasis .text .iconsBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 7px 5px 5px 5px; 
    padding: 0px 5px 0px 0px;
    flex-wrap: wrap;

}

.PropertyUnicEmphasis .text .iconsBox .iconUnicBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: 0px;
    color: var(--Gray);
}
.PropertyUnicEmphasis .text .iconsBox .iconUnicBox svg {
    font-size: 18px;
    margin-right: 5px;
    color: var(--Paragraph);
    margin-top: -2px;

}

.PropertyUnicEmphasis .text .iconsBox .iconUnicBox .simbolBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: center;
    margin-top: 1px;
}
.PropertyUnicEmphasis .text .iconsBox .iconUnicBox .simbolBox  p {
    color: var(--Gray);
    font-weight: 600;
    font-size: 10px;
}

.PropertyUnicEmphasis .text .pricing {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: flex-start;
    margin-top: 5px;
    padding: 0px 5px 0px 0px;
}

.PropertyUnicEmphasis .text .pricing h6 {
    color: var(--Gray);
    font-weight: 800;
    margin: 0 5px;
    text-align: flex-start;
}
.PropertyUnicEmphasis .text .pricing h6 span {
    font-weight: 500;
    color: var(--Gray);
}
.PropertyUnicEmphasis .text .pricing h3 {
    color: var(--Paragraph);
    font-weight: 400;
    margin: 2px 5px 2px 5px;
    text-align: flex-end;
        width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.PropertyUnicEmphasis .text .pricing span {
    color: var(--Paragraph);
    font-weight: 800;
}
.PropertyUnicEmphasis .text .pricing h4 {
    color: var(--Paragraph);
    font-weight: 400;
    margin: 0px 0px 0px 5px;
    text-align: flex-end;
        width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.PropertyUnicEmphasis .text .pricing h4 span {
    color: var(--Paragraph);
    font-weight: 800;
}



@media (max-width: 1090px) {
    .PropertyUnicBlock {
        /* margin: 20px 0px; */
    }
    
@media (max-width: 950px) {
    .PropertyUnicBlock {
        /* margin: 20px auto; */
    }
    
@media (max-width: 750px) {
    .Featured {
        margin: 350px 0 50px 0;
    }

    @media (max-width: 572px) {
        .Featured {
            margin: 400px 0 50px 0;
        }

        .PropertyUnicBlock {
            width: 80%;
        }
       
        .PropertyUnicBlock .text .iconsBox .iconUnicBox {
            width: 20%;
        }

        .PropertyUnicEmphasis {
            width: 80%;
        }
       
        .PropertyUnicEmphasis .text .iconsBox .iconUnicBox {
            width: 20%;
        }

        @media (max-width: 450px) {

        .PropertyUnicBlock {
            width: 95%;
        }

        .PropertyUnicBlock .text .iconsBox .iconUnicBox {
            width: 30%;
        }
        .PropertyUnicEmphasis {
            width: 95%;
        }

        .PropertyUnicEmphasis .text .iconsBox .iconUnicBox {
            width: 30%;
        }
    }

    @media (max-width: 350px) {
        .PropertyUnicBlock {
            width: 90%;
            height: none;
        }
        .PropertyUnicEmphasis {
            width: 90%;
            height: none;
        }
    }
}
}
}
}