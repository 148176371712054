.TopHeadHome {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    background-image: url("../../assets/images/background10.jpg"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .TopHeadHome .blockTop{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%; 
    background-color: rgba(255, 255, 255, 0.90);
    backdrop-filter: blur(5px);
    padding: 0 20px;
    border-bottom: 1px solid var(--Border);
  }
.TopHeadHome .blockTop .SearchText{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 450px;
  }
.TopHeadHome .blockTop .SearchText .TextTopSearch{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 40px 0;
  }

  .TopHeadHome .blockTop .SearchText .TextTopSearch h1 {
    font-weight: 800;
    color: var(--Description);
    font-size: 45px;
    line-height: 2.8rem;
    margin-bottom:10px;
  }
  .TopHeadHome .blockTop .SearchText .TextTopSearch h1 span {
    color: var(--Primary);
  }

  .TopHeadHome .blockTop .SearchText .TextTopSearch h4 {
    font-weight: 500;
    color: var(--Gray);
  }

  .TopHeadHome .blockTop .SearchText .TextTopSearch button {
    border: none;
  border-radius: 25px;
    background-color: var(--Primary);
    color: var(--White);
    padding: 10px 20px;
    margin-top: 20px;
  }
.TopHeadHome .blockTop .ImagesTop{
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%; 
  }
.TopHeadHome .blockTop .ImagesTop .imagesLeft{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; 
  }

  .TopHeadHome .blockTop .ImagesTop .imagesLeft .image{
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    width: 95%;
    height: 200px;
    object-fit: cover;
    border-radius: 25px;
    margin: 10px;
  }
.TopHeadHome .blockTop .ImagesTop .imagesLeft .image img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 25px;
  }


.TopHeadHome .blockTop .ImagesTop .imagesRigth{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%; 
  }
.TopHeadHome .blockTop .ImagesTop .imagesRigth .image{
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    width: 95%;
    height: 200px;
    object-fit: cover;
  border-radius: 25px;
    margin: 10px;
  }
.TopHeadHome .blockTop .ImagesTop .imagesRigth .image img{
    width: 100%;
    height: 200px;
    object-fit: cover;
  border-radius: 25px;
  }

  @media (max-width: 900px){

    .TopHeadHome .blockTop{
      padding: 0 5px;
    }
    
    .TopHeadHome .blockTop .SearchText .TextTopSearch{
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: 40px 0 20px;
    }
  

    .TopHeadHome .blockTop .SearchText .TextTopSearch h1 {
      font-weight: 900;
      font-size: 30px;
      line-height: 2.0rem;
      margin-bottom:10px;
    }


    .TopHeadHome .blockTop .ImagesTop{
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%; 
      margin-top: 150px;
    }

    
.TopHeadHome .blockTop .ImagesTop .imagesLeft{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.TopHeadHome .blockTop .ImagesTop .imagesLeft .image{
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  width: 95%;
  height: 150px;
  object-fit: cover;
  border-radius: 6px;
  margin: 10px;
}
.TopHeadHome .blockTop .ImagesTop .imagesLeft .image img{
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 6px;
}


.TopHeadHome .blockTop .ImagesTop .imagesRigth{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.TopHeadHome .blockTop .ImagesTop .imagesRigth .image{
box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  width: 95%;
  height: 150px;
  object-fit: cover;
  border-radius: 6px;
  margin: 10px;
}
.TopHeadHome .blockTop .ImagesTop .imagesRigth .image img{
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 6px;
}

@media (max-width: 700px) {

  .TopHeadHome .blockTop{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-top: 100px;
  }


  .TopHeadHome .blockTop .SearchText{
    width: 100%;
  }

  .TopHeadHome .blockTop .ImagesTop{
    display: none;
  }
}
  }
  