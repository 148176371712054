.CompanyList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CompanyList .listProperties {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:0 20px 40px 20px;
}
.CompanyList .listProperties .empyt{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.CompanyList .listProperties .empyt img {
    height: 250px;
}

.CompanyList .listProperties .empyt h4 {
    color: var(--Gray);
    margin: 10px 0;
    text-align: center;
}

.CompanyList .searchCompanies {
    width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}

.CompanyList .searchCompanies input {
    width: 50%;
    height: 40px;
    padding: 10px 20px;
    border: 1px solid var(--Border);
    border-radius: 20px;
    margin: 2px;
}
.CompanyList .searchCompanies select {
    width: 50%;
    height: 40px;
    padding: 10px 20px;
    border: 1px solid var(--Border);
    border-radius: 20px;
    margin: 2px;
}

.CompanyList .listProperties .propertyUnicFavorite {
    width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--White);
    border-radius: 50px;
    margin:5px;
    border: 1px solid var(--Border);
    padding: 10px;
}

.CompanyList .listProperties .propertyUnicFavorite .image {
    width:90px; 
    height:90px;
    border-radius: 50px;
    object-fit: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--Border);
    background-color: var(--White);
}
.CompanyList .listProperties .propertyUnicFavorite .image img {
    width: 85px; 
    height: 85px;
    border-radius: 50px;
    object-fit: contain;
    margin: 3px;
}


.CompanyList .listProperties .propertyUnicFavorite .featured {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border-radius: 20px;
    position: absolute;
    margin-top: -15px;
    margin-left: 10px;
    padding: 10px 40px;
}

.CompanyList .listProperties .propertyUnicFavorite .featured p {
    color: var(--White);
    font-weight: 700;
}

.CompanyList .listProperties .propertyUnicFavorite .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Secondary);
    border-radius: 20px;
    position: absolute;
    margin-top: 160px;
    margin-left: 5px;
    padding: 10px 20px;
}

.CompanyList .listProperties .propertyUnicFavorite .status p {
    color: var(--White);
    font-weight: 700;
}





.CompanyList .listProperties .propertyUnicFavorite .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.CompanyList .listProperties .propertyUnicFavorite .text2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
}

.CompanyList .listProperties .propertyUnicFavorite .text2 .heartFavorite {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100%;
}

.CompanyList .listProperties .propertyUnicFavorite .text2 .heartFavorite svg {
    color: var(--White);
    font-size: 30px;
    cursor: pointer;
}
.CompanyList .listProperties .propertyUnicFavorite .text2 .heartFavorite svg:hover {
    color: var(--Primary);
}
.CompanyList .listProperties .propertyUnicFavorite .text2 .heartFavorite2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 100%;
}

.CompanyList .listProperties .propertyUnicFavorite .text2 .heartFavorite2 svg {
    color: var(--Primary);
    font-size: 30px;
    cursor: pointer;
    margin-right: 5px;
}
.CompanyList .listProperties .propertyUnicFavorite .text2 .heartFavorite svg:hover {
    color: var(--Primary);
}

.CompanyList .listProperties .propertyUnicFavorite .text a {
    text-decoration: none;
}
.CompanyList .listProperties .propertyUnicFavorite .text h4 {
    color: var(--Primary);
    font-weight: 700;
    margin: 5px 10px 0px 10px;
}
.CompanyList .listProperties .propertyUnicFavorite .text h6 {
    color: var(--Gray);
    margin: 2px 10px;
    font-weight: 700;
}
.CompanyList .listProperties .propertyUnicFavorite .text h6 svg{
    margin-right: 3px;
}
.CompanyList .listProperties .propertyUnicFavorite .text h5 {
    color: var(--Gray);
    margin:2px 10px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.CompanyList .listProperties .propertyUnicFavorite .text h5 svg {
    margin-right: 5px;
}
.CompanyList .listProperties .propertyUnicFavorite .text .LinkCompany {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border-radius: 20px;
    background-color: var(--White);
    border: none;
    margin: 10px 10px 10px 10px;
    color: var(--Primary);
    font-size: 12px;
}


.CompanyList .listProperties .propertyUnicFavorite .text .icons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 7px 0;
    flex-wrap: wrap;
}

.CompanyList .listProperties .propertyUnicFavorite .text .icons .iconUnic {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    margin: 0 10px;
}

.CompanyList .listProperties .propertyUnicFavorite .text .icons .iconUnic p {
    color: var(--Paragraph);
    font-weight: 700;
}
.CompanyList .listProperties .propertyUnicFavorite .text .icons .iconUnic .simbol {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    margin-top: 5px;
}
.CompanyList .listProperties .propertyUnicFavorite .text .icons .iconUnic .simbol p {
    font-size: 13px;
    color: var(--Paragraph);
    font-weight: 700;
}

.CompanyList .listProperties .propertyUnicFavorite .text .icons .iconUnic .simbol svg {
    margin-right: 3px;
    color: var(--Gray);
}
.CompanyList .listProperties .propertyUnicFavorite .text .pricing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: flex-start;
}

.CompanyList .listProperties .propertyUnicFavorite .text .pricing h5 {
    color: var(--Paragraph);
    font-weight: 700;
    margin: 0 10px;
}
.CompanyList .listProperties .propertyUnicFavorite .text .pricing h2 {
    color: var(--Primary);
    font-weight: 700;
    margin: 0 10px;
}

@media (max-width: 900px) {

    @media (max-width: 800px) {
        .CompanyList .listProperties {
            width: 97%;
            margin: 0 20px;
        }

        @media (max-width: 711px) {
            
            @media (max-width: 615px) {
                .CompanyList .searchCompanies {
                    width: 98%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin: 10px 0;
                }
                .CompanyList .listProperties .propertyUnicFavorite {
                    width: 100%;
                }
                .CompanyList .listProperties .propertyUnicFavorite .text .icons .iconUnic {
                    width: 100%;
                }

                /* .CompanyList .listProperties .propertyUnicFavorite .image {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%; 
                    height: 50px;
                }
                .CompanyList .listProperties .propertyUnicFavorite .image img {
                    height: 50px;
                    margin: 0px 15px -15px 15px;
                } */

                .CompanyList .listProperties .propertyUnicFavorite .text h4 {
                    color: var(--Primary);
                    font-weight: 700;
                    margin: 0px 10px 0px 10px;
                }

                .CompanyList .listProperties .propertyUnicFavorite .text2 .heartFavorite2 svg {
                    font-size: 24px;
                }

                .CompanyList .listProperties .propertyUnicFavorite .text2 {
                    width: 30px;
                }

                @media (max-width: 515px) {

                    .CompanyList .searchCompanies {
                        width: 98%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin: 10px 0;
                    }

                    .CompanyList .searchCompanies input {
                        width: 100%;
                    }
                    .CompanyList .searchCompanies select {
                        width: 100%;
                    }
                    

                    /* .CompanyList .listProperties .propertyUnicFavorite  {
                        width: 98%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                    }
                    .CompanyList .listProperties .propertyUnicFavorite .text {
                        justify-content: flex-start;
                        padding: 10px 5px;
                    }


                    .CompanyList .listProperties .propertyUnicFavorite .text {
                        justify-content: flex-start;
                    }

                    
                    .CompanyList .listProperties .propertyUnicFavorite .text .pricing h5 {
                        display: none;
                    }

                    .CompanyList .listProperties .propertyUnicFavorite .text h2 {
                        font-size: 24px;
                    } */


                    @media (max-width: 450px) {
                        .CompanyList .listProperties .propertyUnicFavorite .text h2 {
                            font-size: 20px;
                        }
    

                        @media (max-width: 450px) {
                      
                            
                            .CompanyList .listProperties .propertyUnicFavorite .text .icons .iconUnic p {
                                font-size: 12px;
                            }
                            
                            .CompanyList .listProperties .propertyUnicFavorite .text .icons .iconUnic .simbol svg {
                                font-size: 14px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
            }
        }
    }

