.FilterPropertiesList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.FilterPropertiesList .filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    Background-color: var(--White);
    color: var(--Paragraph);
    font-weight: 500;
    border: 1px solid var(--Gray);
    border-radius: 25px;
    font-size: 12px;
    margin: 0px;
}

.FilterPropertiesList .searchItens {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column nowrap;
    background: var(--BorderHover);
    border-left: 1px solid var(--Border);
    backdrop-filter: blur(4px);
    position: fixed;
    transform: translateX(0);
    top: 0;
    right: 0;
    height: 100vh;
    width: 650px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 99;
    box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 20px 0px rgba(0,0,0,0.3);
}
.FilterPropertiesList .searchItensNone {
    transform: translateX(100%);
    flex-flow: column nowrap;
    background: var(--White);
    border-left: 1px solid var(--Border);
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 500px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 99;
}
.FilterPropertiesList .searchItens .buttons {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.FilterPropertiesList .searchItens .buttons button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    Background-color: var(--White);
    color: var(--Paragraph);
    font-weight: 500;
    border: 1px solid var(--Gray);
    border-radius: 30px;
    font-size: 12px;
    margin-top: -50px;
}

.FilterPropertiesList .searchItens .searchOptions {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.FilterPropertiesList .searchItensNone .searchOptions {
    width: 95%;
    display: none;
}

.FilterPropertiesList .searchItensNone .buttons {
    width: 95%;
    display: none;
}

.FilterPropertiesList .searchItens .searchOptions button svg {
    margin-right: 5px;
}
.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 5px;
}
.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
}

.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search {
    border: 1px solid var(--Gray);
    background-color: var(--White);
    color: var(--Paragraph);
    border-radius: 30px;
    padding: 0px;
    width: 100%;
    margin: 0px 0 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search input {
    color: var(--Paragraph);
    padding: 15px;
    width: 100%;
    border: 1px solid var(--White);
    border-radius: 30px;
}
.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search .btnClear {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: none;
    background-color: var(--Border);
    padding: 3px;
    color: var(--Gray2);
    margin: 0px 10px;
}

.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search .btnClear svg {
    margin: 0px;

}

.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search3 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
}



.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search3 .listAdress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    padding:7px;
    margin-right: 0%;
    overflow: auto;
    max-height: 150px;
}

.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search3 .listAdress h6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 98%;
    margin: 1px 3px;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 20px;
    color: var(--Description);
    font-weight: 700;
    cursor: pointer;
}
.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search3 .listAdress .itemListAdress {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 98%;
    margin: 1px 3px;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 20px;
    color: var(--Gray2);
    font-weight: 500;
    cursor: pointer;
}

.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search3 .listAdress::-webkit-scrollbar {
    width: 12px;  
    border-radius: 50px;              /* width of the entire scrollbar */
  }
  
  .FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search3 .listAdress::-webkit-scrollbar-track {
    background: var(--BorderHover);
    border-radius: 50px;         /* color of the tracking area */
  }
  
  .FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search3 .listAdress::-webkit-scrollbar-thumb {
    background-color: var(--Border);    /* color of the scroll thumb */
    border-radius: 50px;       /* roundness of the scroll thumb */
    border: 3px solid var(--BorderHover);  /* creates padding around scroll thumb */
  }


.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
}

.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects h4 {
    width: 20%;
    color: var(--Paragraph);
    font-weight: 600;
    margin-right: 5px;
}

.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects input {
    border: 1px solid var(--Gray);
    background-color: var(--White);
    color: var(--Paragraph);
    border-radius: 30px;
    padding: 15px;
    width: 100%;
    margin: 0px 0 20px 0;
}
.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects .selectInput {
    border: 1px solid var(--Primary);
    background-color: var(--White);
    color: var(--Primary);
    border-radius: 30px;
    padding: 15px;
    width: 100%;
    margin: 10px 0 10px 0;
}
.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects select {
    border: 1px solid var(--Gray);
    background-color: var(--White);
    color: var(--Paragraph);
    border-radius: 30px;
    padding: 10px 20px;
    width: 100%;
}
.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects .select {
    border: 1px solid var(--Primary);
    background-color: var(--White);
    color: var(--Primary);
    border-radius: 30px;
    padding: 10px 20px;
    width: 100%;
}

.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelectsButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}


.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelectsButtons button {
    background-color: var(--White);
    padding:15px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid var(--Primary);
    border-radius: 30px;
    color: var(--Primary);
    font-size: 14px;
    width: 50%;
    margin: 5px;
}
.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelectsButtons .btn {
    background-color: var(--White);
    padding:15px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid var(--Gray2);
    border-radius: 30px;
    color: var(--Paragraph);
    font-size: 14px;
    width: 50%;
    margin: 5px;
}
.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelectsButtonsAction {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}


.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelectsButtonsAction button {
    background-color: var(--Primary);
    padding:10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid var(--Primary);
    border-radius: 30px;
    color: var(--White);
    font-size: 14px;
    width: 50%;
    margin: 5px;
}
.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelectsButtonsAction .btn {
    background-color: var(--White);
    padding:10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid var(--Gray2);
    border-radius: 30px;
    color: var(--Paragraph);
    font-size: 14px;
    width: 50%;
    margin: 5px;
}


.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions  .textLocation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 5px 0;
}


.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions  .textLocation h4 {
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--Paragraph);
    font-weight: 700;
}

.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions  .textLocation select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    Background-color:var(--BorderInput2);
    color: var(--Paragraph);
    font-weight: 500;
    border: none;
    margin: 5px;
    border-radius: 30px;
}

.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions  .textLocation button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    Background-color:var(--BorderInput2);
    color: var(--Paragraph);
    font-weight: 500;
    border: none;
    margin: 5px 10px;
    border-radius: 30px;
}

@media(max-width:670px) {
    .FilterPropertiesList .searchItens {
        width: 100%;
        padding: 20px 10px;
    }

    .FilterPropertiesList .searchItens .buttons button {
        margin-top:0px;
    }

    .FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects h4 {
        color: var(--Paragraph);
        font-weight: 600;
        margin-right: 5px;
    }

    @media(max-width: 600px) {
        .FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects h4 {
            width: 30%;
            color: var(--Paragraph);
            font-weight: 600;
            margin-right: 5px;
        }
    
    }

    
    @media(max-width: 450px) { 

        .FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects2 .search .btnClear {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            border: none;
            background-color: var(--Border);
            padding: 3px;
            color: var(--Gray2);
            margin: 0px 10px;
            width: 20px;
            height: 20px;
        }

        
.FilterPropertiesList .searchItens .searchOptions {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
 .FilterPropertiesList .searchItens .searchOptions button {
            width: 70%;
            padding: 7px;
            margin: 5px 0;
        }

.FilterPropertiesList .searchItens .searchOptions .dataSearchOptions .dataSelects h4 {
            width: 40%;
            color: var(--Paragraph);
            font-weight: 600;
            margin-right: 5px;
        }
    }

}