.buttonFavorite {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border-radius: 20px;
    padding: 10px 20px;
    color: var(--White);
    cursor: pointer;
    margin: 5px;
    border: 1px solid var(--Primary);
}
.buttonFavorite svg {
    margin-right: 10px;
}
.buttonFavorite:hover {
    background-color: var(--White);
    color: var(--Primary);
    border: 1px solid var(--Primary);
}

.content-modal-Favorite {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    background-color: var(--White);
}
.content-modal-Favorite .itensModalFavorite {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow:auto; 
    max-height: 500px;
    margin: 20px 10px;
    padding: 20px;
}

.content-modal-Favorite .itensModalFavorite svg {
    color: var(--Primary);
    font-size: 45px;
}
.content-modal-Favorite .itensModalFavorite h1 {
    font-weight: 800;
    color: var(--Paragraph);
    margin: 15px 0;
}
.content-modal-Favorite .itensModalFavorite h4 {
    margin-right: 30px;
}

.content-modal-Favorite .itensModalFavorite button {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    background-color: var(--Primary);
    color: var(--White);
    margin: 30px 0 10px 0px;
    font-weight: 700;
    text-decoration: none;
    font-size: 14px;
}


.content-modal-Favorite .itensModalFavorite .form {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 20px 0;
}
.content-modal-Favorite .itensModalFavorite .form .buttons {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.content-modal-Favorite .itensModalFavorite .form .buttons .button1 {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Border);
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: var(--Gray2);
    font-weight: 700;
    margin: 20px 5px;
}
.content-modal-Favorite .itensModalFavorite .form .buttons .button2 {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: var(--White);
    font-weight: 700;
    margin: 20px 5px;
}
.content-modal-Favorite .itensModalFavorite .form .buttons .btn {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Button);
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: var(--White);
    font-weight: 700;
    margin: 20px 0;
}
.content-modal-Favorite .itensModalFavorite .form .buttons .btn2 {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Border);
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: var(--Paragraph);
    font-weight: 700;
    margin: 20px 0;
}

.content-modal-Favorite .itensModalFavorite .form input {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-bottom: 2px solid var(--Border);
    padding: 5px;
    margin: 5px 0;
    font-weight: bold;
}

.content-modal-Favorite .itensModalFavorite .form input::placeholder {
    color: var(--Gray);
}

.content-modal-Favorite .itensModalFavorite .form .message {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--Primary);
    font-weight: bold;
    margin: 10px 0;
    background-color: var(--White);
    border: 1px solid var(--Primary);
    border-radius: 20px;
    padding: 10px;
}

.content-modal-Favorite .itensModalFavorite .form .message h5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-align: center;
}

.content-modal-Favorite .itensModalFavorite .form .message h5 svg {
    margin-right: 3px;
    font-size: 15px;
}


.content-modal-Favorite .itensModalFavorite .form .links {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: var(--Gray);
    font-weight: bold;
    margin: 10px 0;
}

.content-modal-Favorite .itensModalFavorite .form .links p .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Wite);
    border: none;
    color: var(--Gray);
    font-weight: 700;
    text-decoration: none;
    padding: 5px;
    width: 100%;
    margin: 0px;
}


.content-modal-Favorite .itensModalFavorite .form button {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Button);
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: var(--White);
    font-weight: 700;
    margin: 20px 0;
}
