.Property {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Property .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 65px 0;
}
.Property .main .data {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin: 20px 0;
}


.Property .main .data .propertyUnic {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--White);
    border-radius: 20px;
    margin: 20px;
    padding-bottom: 20px;
}

.Property .main .data .propertyUnic .targets {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    flex-wrap: wrap;
}

.Property .main .data .propertyUnic .targets .featured {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border-radius: 20px;
    padding: 10px 40px;
    margin: 5px;
}

.Property .main .data .propertyUnic .targets .featured p {
    color: var(--White);
    font-weight: 700;
}

.Property .main .data .propertyUnic .targets .iconAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100px;
}
.Property .main .data .propertyUnic .targets .ref {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--White);
    border: 1px solid var(--Gray2);
    color: var(--Gray2);
    border-radius: 20px;
    padding: 10px 15px;
    cursor: pointer;
    margin: 5px;
}

.Property .main .data .propertyUnic .targets .ref p {
    color: var(--Gray2);
    font-weight: 400;
}

.Property .main .data .propertyUnic .targets .exclusive {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border: 1px solid var(--Primary);
    color: var(--White);
    border-radius: 20px;
    padding: 10px 15px;
    cursor: pointer;
    margin: 5px;
}

.Property .main .data .propertyUnic .targets .exclusive p {
    color: var(--White);
    font-weight: 400;
}


.Property .main .data .propertyUnic .targets .status2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Secondary);
    border-radius: 20px;
    padding: 10px 20px;
    margin: 5px;
}

.Property .main .data .propertyUnic .targets .status2 p {
    color: var(--White);
    font-weight: 700;
}


.Property .main .data .propertyUnic .targets .heart svg {
    color: var(--Border);
    font-size: 30px;
    cursor: pointer;
}
.Property .main .data .propertyUnic .targets .heart svg:hover {
    color: var(--Primary);
}




.Property .main .data .propertyUnic .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.Property .main .data .propertyUnic .text h2 {
    color: var(--Paragraph);
    font-weight: 700;
    margin: 20px 10px 2px 10px;
}
.Property .main .data .propertyUnic .text h4 {
    font-weight: 700;
    color: var(--Paragraph);
    margin:10px 10px;
}
.Property .main .data .propertyUnic .text .description {
    font-weight: 400;
    color: var(--Paragraph);
    margin:5px 10px;
    white-space: pre-line;
}
.Property .main .data .propertyUnic .text h3 {
    color: var(--Paragraph);
    font-weight: 700;
    margin: 20px 10px 0px 10px;
}
.Property .main .data .propertyUnic .text h5 {
    color: var(--Gray);
    margin: 2px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Property .main .data .propertyUnic .text h5 svg {
    margin-right: 3px;
}



.Property .main .data .propertyUnic .text .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Secondary);
    border-radius: 20px;
    padding: 10px 20px;
    margin: 5px;
}

.Property .main .data .propertyUnic .text .status p {
    color: var(--White);
    font-weight: 700;
}
.Property .main .data .propertyUnic .availability1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Secondary);
    border-radius: 20px;
    padding: 10px 20px;
    margin: 5px;
}

.Property .main .data .propertyUnic .availability1 p {
    color: var(--White);
    font-weight: 700;
    font-size: 16px;
}
.Property .main .data .propertyUnic .availability2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Aproved);
    border-radius: 20px;
    padding: 10px 20px;
    margin: 5px;
}

.Property .main .data .propertyUnic .availability2 p {
    color: var(--White);
    font-weight: 700;
    font-size: 16px;
}
.Property .main .data .propertyUnic .availability3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border-radius: 20px;
    padding: 10px 20px;
    margin: 5px;
}

.Property .main .data .propertyUnic .availability3 p {
    color: var(--White);
    font-weight: 700;
    font-size: 16px;
}

.Property .main .data .propertyUnic .text .heart {
    margin: 10px;
}
 
.Property .main .data .propertyUnic .text .iconsBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px 0;
    padding: 10px 0;
    flex-wrap: wrap;
    border: none;
}

.Property .main .data .propertyUnic .text .iconsBox .iconUnicBox {
    width: 220px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    padding: 7px;
    margin: 5px 0;
    border-radius: 6px;
    color: var(--Paragraph);
    background-color: var(--BorderHover);
}

.Property .main .data .propertyUnic .text .iconsBox .iconUnicBox p {
    color: var(--Gray2);
    font-weight: 700;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.Property .main .data .propertyUnic .text .iconsBox .iconUnicBox p sup {
    vertical-align: top;
    font-size: 10px;
    margin-top: -7px;
}

sup {
    vertical-align: super;
    font-size: smaller;
}
.Property .main .data .propertyUnic .text .iconsBox .iconUnicBox svg {
    font-size: 22px;
    margin-right: 10px;
    color: var(--Paragraph);
}


.Property .main .data .propertyUnic .text .iconsBox .iconUnicBox .simbolBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: center;
}

.Property .main .data .propertyUnic .text .iconsBox .iconUnicBox .simbolBox svg {
    font-size: 20px;
    margin-right: 5px;
    color: var(--Description);
}


.Property .main .data .propertyUnic .text .iconsBox .iconUnicBox .simbolBox p {
    color: var(--Description);
    font-weight: 400;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.Property .main .data .propertyUnic .text .characteristics {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 10px 0;
}

.Property .main .data .propertyUnic .text .characteristics .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--Paragraph);
    padding: 5px 15px 5px 5px;
    border-radius: 20px;
   
}

/* background-color: rgba(170,0,0, 0.03); */


.Property .main .data .propertyUnic .text .characteristics .item svg {
    color: var(--Primary);
    margin-right: 3px;
    font-size: 16px;
}
.Property .main .data .propertyUnic .text .characteristics .item p {
    color: var(--Paragraph);
    font-weight: 600;
}


.Property .main .data .propertyUnic .text .infosCompanyMobile {
    width: 100%;
    display: none;
    border-radius: 20px;
    border: 1px solid var(--Border);
    margin: 5px;
    padding: 10px;
    margin-top: 20px;
}
.Property .main .data .propertyUnic .text .infosCompanyMobile .pricing h3 {
    color: var(--Paragraph);
    font-weight: 400;
    margin: 0 10px;
}
.Property .main .data .propertyUnic .text .infosCompanyMobile .pricing h3 span {
    color: var(--Paragraph);
    font-weight: 800;
}

.Property .main .data .propertyUnic .text .infosCompanyMobile  .financing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--BorderHover);
    padding: 5px;
    border-radius: 20px;
}
.Property .main .data .propertyUnic .text .infosCompanyMobile  .financing .financingClient {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.Property .main .data .propertyUnic .text .infosCompanyMobile  .financing .financingFipe {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5px;
}

.Property .main .data .propertyUnic .text .infosCompanyMobile  .financing .financingClient h5 {
    color: var(--Paragraph);
    font-weight: 600;
    margin: 0px 10px 5px 10px;
}
.Property .main .data .propertyUnic .text .infosCompanyMobile  .financing .financingClient h6 {
    color: var(--Paragraph);
    font-weight: 500;
    margin: 0px 10px 5px 10px;
}
.Property .main .data .propertyUnic .text .infosCompanyMobile  .financing .financingClient h2 {
    color: var(--Primary);
    font-weight: 700;
    margin: 0 10px;
}
.Property .main .data .propertyUnic .text .infosCompanyMobile  .financing .financingFipe h5 {
    color: var(--Paragraph);
    font-weight: 600;
    margin: 5px 10px;
    text-align: right;
}
.Property .main .data .propertyUnic .text .infosCompanyMobile  .financing .financingFipe h3 {
    color: var(--Primary);
    font-weight: 700;
    margin: 0 10px;
    text-align: right;
}
.Property .main .data .propertyUnic .text .infosCompanyMobile  .financing .financingFipe button {
    font-size: 14px;
    color: var(--Primary);
    font-weight: 400;
    text-decoration: none;
    text-align: right;
    border: 1px solid var(--Primary);
    padding: 5px 10px;
    background: var(--White);
    font-size: 16px;
    font-weight: 700;
    margin: 0 5px;
    border-radius: 20px;
}



.Property .main .data .propertyUnic .text .pricing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: flex-start;
    margin-bottom: 5px;
}

.Property .main .data .propertyUnic .text .pricing h5 {
    color: var(--Gray);
    font-weight: 800;
    margin: 5px 10px;
}
.Property .main .data .propertyUnic .text .pricing h5 span {
    font-weight: 500;
}
.Property .main .data .propertyUnic .text .pricing h2 {
    color: var(--Paragraph);
    font-weight: 400;
    margin: 0 10px;
}
.Property .main .data .propertyUnic .text .pricing h2 span {
    color: var(--Paragraph);
    font-weight: 800;
}
.Property .main .data .propertyUnic .text .pricing h3 {
    color: var(--Paragraph);
    font-weight: 400;
    margin: 0 10px;
}
.Property .main .data .propertyUnic .text .pricing h3 span {
    color: var(--Paragraph);
    font-weight: 800;
}

.Property .main .data .propertyUnic .text .otherPrincings {
    width: 600px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid var(--Border);
    padding: 5px 0;
}

.Property .main .data .propertyUnic .text .otherPrincings h5 {
    color: var(--Gray);
    font-weight: 700;
    margin: 5px 10px;
}

.Property .main .data .propertyUnic .text .pricingTotal {
    width: 600px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px 0;
    margin-bottom: 20px;
}

.Property .main .data .propertyUnic .text .pricingTotal h4 {
    color: var(--Paragraph);
    font-weight: 800;
    margin: 5px 10px;
}
.Property .main .data .propertyUnic .text .pricingTotal h5 {
    color: var(--Paragraph);
    font-weight: 600;
    margin: 5px 10px;
}
.Property .main .data .propertyUnic .text .pricingTotal h6 {
    color: var(--Paragraph);
    font-weight: 600;
    margin: 5px 10px;
}


.Property .main .data .propertyUnic .text .video {
    width: 100%;
    margin: 20px 0;
    border-radius: 20px;
}
.Property .main .data .propertyUnic .text .map {
    width: 100%;
    margin: 20px 0;
    border-radius: 20px;
}

.Property .main .data .propertyUnic .text .map h6 {
    font-size: 12px;
    color: var(--Gray);
}




.Property .main .data .infosCompany {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--White);
    border-radius: 20px;
    border: 1px solid var(--Border);
    margin: 20px ;
    padding: 10px;
    margin-top: 20px;
}

.Property .main .data .infosCompany .pricing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: flex-start;
    margin-bottom: 5px;
}

.Property .main .data .infosCompany .pricing h5 {
    color: var(--Gray);
    font-weight: 800;
    margin: 5px 10px;
}
.Property .main .data .infosCompany .pricing h5 span {
    font-weight: 500;
}
.Property .main .data .infosCompany .pricing h2 {
    color: var(--Paragraph);
    font-weight: 400;
    margin: 0 10px;
}
.Property .main .data .infosCompany .pricing h2 span {
    color: var(--Paragraph);
    font-weight: 800;
}
.Property .main .data .infosCompany .pricing h3 {
    color: var(--Paragraph);
    font-weight: 400;
    margin: 0 10px;
}
.Property .main .data .infosCompany .pricing h3 span {
    color: var(--Paragraph);
    font-weight: 800;
}

.Property .main .data .infosCompany .otherPrincings {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid var(--Border);
    padding: 5px 0;
}

.Property .main .data .infosCompany .otherPrincings h5 {
    color: var(--Gray);
    font-weight: 700;
    margin: 5px 10px;
}

.Property .main .data .infosCompany .pricingTotal {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px 0;
}

.Property .main .data .infosCompany .pricingTotal h4 {
    color: var(--Paragraph);
    font-weight: 800;
    margin: 5px 10px;
    margin-bottom: 20px;
}
.Property .main .data .infosCompany .pricingTotal h5 {
    color: var(--Paragraph);
    font-weight: 600;
    margin: 5px 10px;
    margin-bottom: 20px;
}
.Property .main .data .infosCompany .pricingTotal h6 {
    color: var(--Paragraph);
    font-weight: 600;
    margin: 5px 10px;
    margin-bottom: 20px;
}


.Property .main .data .infosCompany .financing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--BorderHover);
    padding: 10px;
    border-radius: 20px;
}
.Property .main .data .infosCompany .financing .financingClient {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.Property .main .data .infosCompany .financing .financingFipe {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5px;
}

.Property .main .data .infosCompany .financing .financingClient h5 {
    color: var(--Paragraph);
    font-weight: 600;
    margin: 0px 10px 5px 10px;
}
.Property .main .data .infosCompany .financing .financingClient h6 {
    color: var(--Paragraph);
    font-weight: 500;
    margin: 0px 10px 5px 10px;
}
.Property .main .data .infosCompany .financing .financingClient h2 {
    color: var(--Primary);
    font-weight: 700;
    margin: 0 10px;
}
.Property .main .data .infosCompany .financing .financingFipe h5 {
    color: var(--Paragraph);
    font-weight: 600;
    margin: 5px 10px;
    text-align: right;
}
.Property .main .data .infosCompany .financing .financingFipe h3 {
    color: var(--Primary);
    font-weight: 700;
    margin: 0 10px;
    text-align: right;
}
.Property .main .data .infosCompany .financing .financingFipe button {
    font-size: 14px;
    color: var(--Primary);
    font-weight: 400;
    text-decoration: none;
    text-align: right;
    border: 1px solid var(--Primary);
    padding: 5px 10px;
    background: var(--White);
    font-size: 16px;
    font-weight: 700;
    margin: 0 10px;
    border-radius: 20px;
}



.content-modal-Favorite {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    background-color: var(--White);
}
.content-modal-Favorite .itensModalFavorite {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow:auto; 
    max-height: 500px;
    margin: 20px 10px;
    padding: 20px;
}

.content-modal-Favorite .itensModalFavorite svg {
    color: var(--Primary);
    font-size: 45px;
}
.content-modal-Favorite .itensModalFavorite h1 {
    font-weight: 800;
    color: var(--Paragraph);
    margin: 15px 0;
}
.content-modal-Favorite .itensModalFavorite h4 {
    margin-right: 30px;
}

.content-modal-Favorite .itensModalFavorite a {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: var(--Primary);
    color: var(--White);
    margin: 30px 0 10px 0px;
    font-weight: 700;
    text-decoration: none;
    font-size: 14px;
}







@media (max-width: 1200px) {
    .Property .main .data {
        width: 95%;
    }
@media (max-width: 1000px) {

    .Property .main .data .propertyUnic {
        width:100%;
    }

    .Property .main .data .infosCompany {
        max-width: 310px;
        min-width: 310px;

    }
    
@media (max-width: 950px) {
    .Property .main .data {
        width: 100%;
    }
    
@media (max-width: 850px) {
    .Property .main .data {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 10px 0;
        padding: 0 10px;
    }
    
    .Property .main .data .propertyUnic {
        width: 90%;
    }

    .Property .main .data .propertyUnic .text .infosCompanyMobile {
        display: block;
    }
    .Property .main .data .infosCompany {
        display: none;
    }
}

@media (max-width: 750px) {
    .Property .main .data {
        padding: 0px;
    }
    @media (max-width: 750px) {
        .Property .main .data .propertyUnic .text .otherPrincings {
            width: 100%;
        }
        
        .Property .main .data .propertyUnic .text .pricingTotal {
            width: 100%;
        }

        @media (max-width: 550px) {
            .Property .main .data .propertyUnic .text .iconsBox .iconUnicBox {
                width: 48%;
            }
        }
        
        
}
}

}
}
}