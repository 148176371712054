.ToHire {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 350px;
    background-color: var(--Primary);
    margin-top: 100px;
}
.ToHire .imageToHire {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 50%;
}
.ToHire .imageToHire .imgToHireIn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 450px;
    height: 300px;
    object-fit: cover;
    background-color: #cccccc;
    border-radius: 6px;
    position: absolute;
    margin-left: 450px;
}

.ToHire .imageToHire .imgToHireIn img {
    width: 100%;
    height: 300px;
    border-radius: 6px;
    object-fit: cover;
}
.ToHire .textToHire {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;;
    width: 100%;
    height: 100%;
    padding: 20px 20px 20px 350px;
    background-color: var(--White);
}
.ToHire .textToHire .itensToHire {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
}
.ToHire .textToHire .itensToHire .ToHireUnic {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 5px;
    margin: 2px;
    color: var(--Primary);
}

.ToHire .textToHire .itensToHire .ToHireUnic h5 {
    font-weight: 700;
    color: var(--Paragraph);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    text-align: left;
    /* padding: 10px 20px;
    border-radius: 30px; */
}

.ToHire .textToHire .itensToHire .ToHireUnic svg {
    margin-right: 5px;
    font-size: 22px;
}

.ToHire .textToHire h2 {
    font-weight: 700;
    color: var(--Paragraph);
    margin:10px 0;
    text-align: left;
}

.ToHire .textToHire h2 span{
    color: var(--Primary);
}

.ToHire .textToHire a {
    background-color: var(--Primary);
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    color: var(--White);
    width: 250px;
    margin: 20px 0;
    font-weight: 700;
    text-decoration: none;
    border-radius: 30px;
}

@media (max-width: 1000px) {
    .ToHire .imageToHire .imgToHireIn {
        width: 350px;
    }

    .ToHire .textToHire {
        padding: 20px 20px 20px 300px;
    }
    
    @media (max-width: 950px) {
        .ToHire .imageToHire .imgToHireIn {
            margin-left: 300px;
        }
        .ToHire .textToHire {
            padding: 20px 20px 20px 250px;
        }


@media (max-width: 800px) {
    .ToHire .imageToHire .imgToHireIn {
        display: none;
    }
    .ToHire .imageToHire {
        width: 150px;
    }

    .ToHire .textToHire {
        padding: 50px 50px 50px 50px;
    }
    @media (max-width: 650px) {
        .ToHire .imageToHire {
            width: 10%;
        }
        .ToHire .textToHire {
            padding:20px 20px 20px 20px ;
        }
}
}
}
}
