.Notification{
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--BorderInput);
    padding: 10px 20px;
    margin: 3px;
    border-radius: 6px;
}

.Notification .infos {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.Notification .infos .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.Notification .infos .text h5 {
    font-weight: 700;
    color: var(--Description);
}

.Notification svg {
    color: var(--Secondary);
    font-size: 15px;
    margin-left: 5px;
}

@media (max-width: 900px) {
    .Notification{
        width: 80%;
    }

    @media (max-width: 650px) {
        .Notification{
            width: 95%;
        }

    }
}