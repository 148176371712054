.Schedules {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}


.Schedules .main {
    width: 100%;
    margin-top: 80px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.Schedules .main .text {
    width: 80%;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin: 10px;
}

.Schedules .main .text .textTop {
    width: 550px;
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Schedules .main .text .textTop h3 {
    font-weight: bold;
    text-align: center;
}

.Schedules .main .text .textTop button {
    border: none;
    color: var(--ButtonDisabled);
    background-color: var(--White);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.Schedules .main .text .textTop button svg {
    margin-right: 3px;
    font-weight: bold;
}

.Schedules .main .text h2 {
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}

.Schedules .main .text h3 {
    font-weight: 700;
    color: var(--Paragraph);
    text-align: center;
}
.Schedules .main .text h3 svg{
    font-size: 45px;
    font-weight: 900;
    color: var(--Primary)
}

.Schedules .main .text .ListSchedules{
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
    padding: 5px 20px;
    border: none;
    border-left: 5px solid var(--Aproved)
}

.Schedules .main .text .ListSchedules .textTitle {
    width: 100%;
}

.Schedules .main .text .ListSchedules .textTitle h5{
    color: var(--Gray);
    font-weight: 700;
    margin-bottom: 10px;
}

.Schedules .main .text .ListSchedules2{
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
    padding: 5px 20px;
    border: none;
    border-left: 5px solid var(--Secondary)
}

.Schedules .main .text .ListSchedules2 .textTitle {
    width: 100%;
}

.Schedules .main .text .ListSchedules2 .textTitle h5{
    color: var(--Gray);
    font-weight: 700;
    margin-bottom: 10px;
}


.Schedules .main .text .ListSchedules3{
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
    padding: 5px 20px;
    border: none;
    border-left: 5px solid var(--Button)
}

.Schedules .main .text .ListSchedules3 .textTitle {
    width: 100%;
}

.Schedules .main .text .ListSchedules3 .textTitle h5{
    color: var(--Gray);
    font-weight: 700;
    margin-bottom: 10px;
}


.Schedules .main .text .chat{
    width: 100%;
    border-radius: 10px;
    background-color: var(--BorderInput);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 5px;
    height: 100px;
}

.Schedules .main .text .chat a {
    height: 100%;
}
.Schedules .main .text .chat .image{
    border-radius: 10px 0 0 10px;
    width: 80px;
    height: 100%;
    object-fit: cover;
    border-right: 3px solid var(--White);
}
.Schedules .main .text .chat .image img{
    border-radius: 10px 0 0 10px;
    width: 80px;
    height: 100%;
    object-fit: cover;
}
.Schedules .main .text .chat .image svg{
    font-size: 70px;
    color: var(--Primary);
}
.Schedules .main .text .chat .image h3{
    color: var(--White);
        position: absolute;
        margin-top: 2px;
        font-size: 16px;
}
.Schedules .main .text .chat .image h4{
    color: var(--White);
        position: absolute;
        margin-top: 35px;
        font-size: 18px;
        font-weight: 900;
}
.Schedules .main .text .chat .textChat{
    border-radius: 10px;
    background-color: var(--BorderInput);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 0 5px;
    text-align:left;
}
.Schedules .main .text .chat .textChat h4 {
    color: var(--Primary);
    font-weight: 700;
    margin: 0 10px;
    text-align:left;
  }
.Schedules .main .text .chat .textChat a {
    text-decoration: none;

  }

  .Schedules .main .text .chat .textChat h5 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    color: var(--Gray2);
    margin: 3px 8px;
    text-align:left;
    font-weight: 700;
  }
  .Schedules .main .text .chat .textChat h6 {
    color: var(--Gray);
    margin: 0 7px 0 7px;
    text-align:left;
    font-weight: 700;
  }
  .Schedules .main .text .chat .textChat h6 svg {
    margin-right: 2px;
  }

@media (max-width: 800px) {
    .Schedules .main .text .ListSchedules{
        width: 500px;
        margin: 5px;
    }
    .Schedules .main .text .ListSchedules2{
        width: 500px;
        margin: 5px;
    }
    .Schedules .main .text .ListSchedules3{
        width: 500px;
        margin: 5px;
    }

    @media (max-width: 700px) {
        .Schedules .main {
            margin-top: 50px;
            margin-bottom: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }

        .Schedules .main .text {
            width: 100%;
            padding: 0px;
        }

                    
            .Schedules .main .text .textTop {
                width: 100%;
                justify-content: center;
            }


        @media (max-width: 700px) {
            .Schedules .main .text .ListSchedules{
                width: 98%;
            }
            .Schedules .main .text .ListSchedules2{
                width: 98%;
            }
            .Schedules .main .text .ListSchedules3{
                width: 98%;
            }
        }
        
    }
}

