.ExploreIconsProperties  {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 80px 0 60px 0;
}
.ExploreIconsProperties .FilterActived {
    width: 20px;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ExploreIconsProperties .FilterInactive {
    width: 0px;
    height: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ExploreIconsProperties .mainExplorer {
    width: 1100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ExploreIconsProperties .mainExplorer  .textExplore {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
}

.ExploreIconsProperties .mainExplorer  .textExplore h2 {
    color: var(--Primary);
    font-weight: 700;
}
.ExploreIconsProperties .mainExplorer  .textExplore h4 {
    color: var(--Paragraph);
    margin:10px 0;
    font-weight: 500;
}
.ExploreIconsProperties .mainExplorer  .textExplore a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 25px;
    text-decoration: none;
    background-color: var(--BorderHover);
    color: var(--Primary);
    margin: 10px 0;
}
.ExploreIconsProperties .mainExplorer  .textExplore a svg {
    margin-left: 5px;
}
.ExploreIconsProperties .mainExplorer  .textExplore a:hover {
    background-color: var(--Primary);
    color: var(--BorderHover);
}
.ExploreIconsProperties .mainExplorer  .iconsExplore {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ExploreIconsProperties .mainExplorer  .iconsExplore a {
    text-decoration: none;
}
.ExploreIconsProperties .mainExplorer  .iconsExplore .IconUnicExplore {
    width: 130px;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    padding: 5px;
    border-radius: 20px;
}
.ExploreIconsProperties .mainExplorer  .iconsExplore .IconUnicExplore:hover {
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.ExploreIconsProperties .mainExplorer  .iconsExplore .IconUnicExplore h5 {
    text-align: center;
    color: var(--Gray);
    margin: 5px;
}

.ExploreIconsProperties .mainExplorer  .iconsExplore .IconUnicExplore svg {
    font-size: 30px;
    color: var(--Primary);
}



@media (max-width: 1120px) {
    .ExploreIconsProperties .mainExplorer {
        width: 95%;
    }
    
    @media (max-width: 1000px) {
        .ExploreIconsProperties .mainExplorer {
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .ExploreIconsProperties .mainExplorer  .textExplore {
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .ExploreIconsProperties .mainExplorer  .iconsExplore {
            width: 100%;
            paddign: 20px;
            flex-wrap: wrap;
        }

        .ExploreIconsProperties .mainExplorer  .iconsExplore .IconUnicExplore {
            width: 120px;
            height: 120px;
        }


        @media (max-width: 660px) {
            .ExploreIconsProperties .mainExplorer  .iconsExplore .IconUnicExplore svg {
                font-size: 24px;
            }

            .ExploreIconsProperties .mainExplorer  .iconsExplore .IconUnicExplore {
                width: 100px;
                height: 100px;
            }
            
        }
    }
}