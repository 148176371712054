.Evaluation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--White);
    margin: 60px 0px 0px 0px;
}

.Evaluation .img {
    height: 40px;
    margin: 20px;
}
.Evaluation .Resume {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
}


.Evaluation .Resume .iconsBox {
    width: 1000px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px 0;
    flex-wrap: wrap;
    border: none;
    padding: 0 10px;
}

.Evaluation .Resume .iconsBox .iconUnicBox {
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    text-align: center;
    padding: 5px;
    margin: 5px 15px 5px 5px;
    color: var(--Gray);
    background-color: var(--BorderHover);
    border-radius: 6px;
}

.Evaluation .Resume .iconsBox .iconUnicBox svg {
    font-size: 18px;
    margin-right: 10px;
    color: var(--Primary);
}


.Evaluation .Resume .iconsBox .iconUnicBox .simbolBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    margin-top: 5px;
}

.Evaluation .Resume .iconsBox .iconUnicBox .simbolBox p {
    color: var(--Gray);
    font-weight: 600;

}

.Evaluation .Address {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 500px;
    padding: 10px;
}
.Evaluation .Address h4 {
    font-weight: 600;
    color: var(--Paragraph);
    text-align: center;
    margin: 0px 20px;
}
.Evaluation .CompaniesEvaluation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 500px;
    padding: 10px;
}

.Evaluation .CompaniesEvaluation h4 {
    font-weight: 600;
    color: var(--Paragraph);
    text-align: center;
    margin: 5px 20px 20px 20px;
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 550px;
    overflow: auto;
}
.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluation {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    width: 95%;
    margin: 5px;
    padding: 5px;
    border: 1px solid var(--Border);
    border-radius: 6px;
    color: var(--Paragraph);
}
.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluationSelected {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    width: 95%;
    margin: 5px;
    padding: 5px;
    border: 1px solid var(--Border);
    border-radius: 6px;
    color: var(--White);
    background-color: var(--Primary);
}
.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluation .imageEvaluation {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    object-fit: cover;
    background-color: var(--White);
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluation .imageEvaluation img {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    object-fit: contain;
}
.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluationSelected .imageEvaluation {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    object-fit: cover;
    background-color: var(--White);
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluationSelected .imageEvaluation img {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    object-fit: contain;
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluation .text {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    margin: 0 10px;
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluation .text h4 {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 0px;
}
.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluation .text h3 {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}


.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluation .text h5 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluation .text h5 svg {
    margin-right: 3px;
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluationSelected .text {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    margin: 0 10px;
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluationSelected .text h4 {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 0px;
    color: var(--White);
}
.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluationSelected .text h3 {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}


.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluationSelected .text h5 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluationSelected .text h5 svg {
    margin-right: 3px;
}

.Evaluation input {
    padding: 10px 20px;
    width: 100%;
    border: 1px solid var(--Border);
    border-radius: 6px;
    margin: 5px 2px;
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluation .buttonCompany {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100%;
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluation .buttonCompany .btnCompany {
    padding: 10px;
    border: 1px solid var(--Border);
    border-radius: 6px;
    margin: 5px;
    color: var(--Primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluation .buttonCompany .btnCompany svg {
    margin-right: 0px;
}
.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluationSelected .buttonCompany {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100%;
}

.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluationSelected .buttonCompany .btnCompany {
    padding: 10px;
    border: 1px solid var(--Border);
    border-radius: 6px;
    margin: 5px;
    color: var(--Primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.Evaluation .CompaniesEvaluation .listCompaniesEvaluation .CompanieUnicEvaluationSelected .buttonCompany .btnCompany svg {
    margin-right: 0px;
}

.Evaluation select {
    padding: 10px 20px;
    width: 100%;
    border: 1px solid var(--Border);
    border-radius: 6px;
    margin: 5px 2px;
}
.Evaluation textarea {
    padding: 10px 20px;
    width: 100%;
    border: 1px solid var(--Border);
    border-radius: 6px;
    margin: 5px 2px;
    resize: none;
}

.Evaluation button {
    padding: 10px 15px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    border-radius: 6px;
    margin: 5px;
    color: var(--Primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}


.Evaluation button svg {
    margin-right: 5px;
    font-size: 16px;
}

.Evaluation .Address .cep {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 300px;
}

.Evaluation .Address .cep .btnCEP {
    padding: 10px 15px;
    border: 1px solid var(--Border);
    background-color: var(--Primary);
    border-radius: 6px;
    margin: 5px;
    color: var(--White);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.Evaluation .Address .cep .btnCEP svg {
    margin-right: 0px;
    font-size: 16px;
}

.Evaluation .Address .text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 10px 10px 0 10px;
}

.Evaluation .Address .text h3 {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}



.Evaluation .Address .data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px 10px;
    width: 450px;
}

.Evaluation .Address .data3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 40px 20px;
    width: 80%;
}

.Evaluation .Address .data3 span {
    color: var(--Gray2);
    font-weight: 700;
    margin: 5px 0;
}

.Evaluation .Resume h2 {
    color: var(--Primary);
    font-weight: 700;
    margin: 5px 0;
}

.Evaluation .Resume .data3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px;
    width: 1000px;

}

.Evaluation .Resume .data3 span {
    color: var(--Gray2);
    font-weight: 700;
    margin: 5px 0;
}

.Evaluation .Resume .textInformations {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 1000px;
    padding: 0 20px;
}

.Evaluation .Resume .textInformations h4 {
    font-weight: 700;
    color: var(--Primary);
    margin: 2px 0;
}
.Evaluation .Resume .textInformations h5 {
    font-weight: 500;
    color: var(--Gray);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 2px 0;
    white-space: pre-line;
}
.Evaluation .Resume .textInformations h5 svg {
    margin:0 3px;
}
.Evaluation .Resume .textInformations .description {
    font-weight: 500;
    color: var(--Gray);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin: 2px 0;
    white-space: pre-line;
}
.Evaluation .Resume .textInformations .description svg {
    margin:0 3px;
}

.Evaluation .Address .data .dataInputs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}
.Evaluation .Address .data2 {
    display: none;
}


.Evaluation .Address .data3 .myImages{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    flex-wrap: wrap;
}
.Evaluation .Address .data3 .myImages .btnImage{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background-color: rgba(255,255,255, 0.1);
    color: var(--White);
    position: absolute;
    margin-left: 125px; 
    margin-top: 80px; 
}

.Evaluation .Address .data3 .myImages .btnImage svg {
    margin: 0px;
}
.Evaluation .Address .data3 .myImages .featuredImage{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px;
    color: var(--White);
    position: absolute;
    margin-right: 125px; 
    margin-top: 80px; 
    background-color: rgba(255,255,255, 0.1);
}

.Evaluation .Address .data3 .myImages .featuredImage svg {
    margin: 0px;
}
.Evaluation .Address .data3 .myImages .imageUnic{
    width: 170px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px;
    object-fit: cover;
    border-radius: 6px;
}
.Evaluation .Address .data3 .myImages .imageUnic img{
    width: 170px;
    height: 100px;
    object-fit: cover;
    border-radius: 6px;
}
.Evaluation .Address .data3 .myImages .imageUnicFeatured{
    width: 170px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px;
    object-fit: cover;
    border-radius: 6px;
    background-color: var(--Primary)
}
.Evaluation .Address .data3 .myImages .imageUnicFeatured img{
    width: 170px;
    height: 100px;
    object-fit: cover;
    border-radius: 6px;
    border: 2px solid var(--Primary)
}
.Evaluation .Resume .data3 .myImages{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    flex-wrap: wrap;
}
.Evaluation .Resume .data3 .myImages .btnImage{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background-color: rgba(255,255,255, 0.1);
    color: var(--White);
    position: absolute;
    margin-left: 125px; 
    margin-top: 80px; 
}

.Evaluation .Resume .data3 .myImages .btnImage svg {
    margin: 0px;
}
.Evaluation .Resume .data3 .myImages .featuredImage{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px;
    color: var(--White);
    position: absolute;
    margin-right: 125px; 
    margin-top: 80px; 
    background-color: rgba(255,255,255, 0.1);
}

.Evaluation .Resume .data3 .myImages .featuredImage svg {
    margin: 0px;
}
.Evaluation .Resume .data3 .myImages .imageUnic{
    width: 170px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px;
    object-fit: cover;
    border-radius: 6px;
}
.Evaluation .Resume .data3 .myImages .imageUnic img{
    width: 170px;
    height: 100px;
    object-fit: cover;
    border-radius: 6px;
}
.Evaluation .Resume .data3 .myImages .imageUnicFeatured{
    width: 170px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px;
    object-fit: cover;
    border-radius: 6px;
    background-color: var(--Primary)
}
.Evaluation .Resume .data3 .myImages .imageUnicFeatured img{
    width: 170px;
    height: 100px;
    object-fit: cover;
    border-radius: 6px;
    border: 2px solid var(--Primary)
}

.Evaluation .stepes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 2px 5px 2px;
    width: 100%;
}
.Evaluation .stepes h5 {
    color: var(--Gray);
}

.Evaluation .stepes .stepeUnicSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid var(--White);
    background-color: var(--Primary);
    color: var(--White)
}
.Evaluation .stepes .stepeUnic {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid var(--Border);
    color: var(--Gray);
}

.Evaluation .searchBrokers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 20px;
    width: 550px;
}
.Evaluation .buttonsStepesPage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 20px;
    width: 350px;
}

.Evaluation .buttonsStepesPage .btnSelect {
    padding: 10px 15px;
    border: 1px solid var(--Border);
    border-radius: 6px;
    margin: 5px;
    color: var(--White);
    background-color: var(--Primary);
    border-radius: 6px;
    width: 45%;
    font-size: 14px;
}
.Evaluation .buttonsStepesPage .btnSelect2 {
    padding: 10px 15px;
    border: 1px solid var(--Border);
    border-radius: 6px;
    margin: 5px;
    color: var(--Primary);
    background-color: var(--White);
    border-radius: 6px;
    width: 45%;
    font-size: 14px;
}
.Evaluation .buttonsStepesPage .btn {
    padding: 10px 15px;
    border: 1px solid var(--Border);
    border-radius: 6px;
    margin: 5px;
    color: var(--Primary);
    background-color: var(--White);
    border-radius: 6px;
    width: 45%;
    font-size: 14px;
}
.Evaluation .buttonsStepesPage .linkCompany {
    padding: 10px 15px;
    border: 1px solid var(--Border);
    border-radius: 6px;
    margin: 5px;
    color: var(--Primary);
    background-color: var(--White);
    border-radius: 6px;
    width: 45%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    text-decoration: none;
}

@media (max-width: 1050px) {

    .Evaluation .Resume .data3 {
        width: 100%;
    
    }
    .Evaluation .Resume .textInformations {
        width: 100%;
    }

    .Evaluation .Resume .iconsBox {
        width: 100%;
    }
@media (max-width: 950px) {
    .Evaluation .Address {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        min-height: 400px;
        height: 100%;
        padding: 10px;
    }
@media (max-width: 580px) {
    .Evaluation .searchBrokers {
        width: 95%;
    }

    .Evaluation .Address .data3 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 40px 20px;
        width: 100%;
    }

    .Evaluation .CompaniesEvaluation .listCompaniesEvaluation {
        width: 100%;
    }
    

    

    @media (max-width: 480px) {

        .Evaluation .stepes {
            margin: 20px 5px 10px 5px;
        }

        .Evaluation .buttonsStepesPage {
            margin: 40px 0px;
        }
        
        .Evaluation .Address {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            width: 100%;
            min-height: 400px;
            height: 100%;
            padding: 10px;
        }

        .Evaluation .Address .cep {
            width: 100%;
        }

        .Evaluation .Address .data {
            width: 100%;
        }

        .Evaluation .Address .data3 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            flex-wrap: wrap;
            margin: 40px 20px;
            width: 100%;
            height: 100%;
        }

        .Evaluation .Address .data .dataInputs {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
        }

        
        .Evaluation .searchBrokers {
            flex-direction: column;
        }


        .Evaluation .buttonsStepesPage {
            width: 100%;
        }

        .Evaluation .Resume .data3 .myImages .imageUnic{
            width: 150px;
            height: 85px;
        }
        .Evaluation .Resume .data3 .myImages .imageUnic img{
            width: 150px;
            height: 85px;
        }

        .Evaluation .Address .data3 .myImages .imageUnic{
            width: 150px;
            height: 85px;
        }
        .Evaluation .Address .data3 .myImages .imageUnic img{
            width: 150px;
            height: 85px;
        }

        .Evaluation .Address .data3 .myImages .imageUnicFeatured{
            width: 150px;
            height: 85px;
        }
        .Evaluation .Address .data3 .myImages .imageUnicFeatured img{
            width: 150px;
            height: 85px;
            border: 4px solid var(--Primary)
        }

        .Evaluation .Resume .data3 .myImages .imageUnicFeatured{
            width: 150px;
            height: 85px;
        }
        .Evaluation .Resume .data3 .myImages .imageUnicFeatured img{
            width: 150px;
            height: 85px;
            border: 4px solid var(--Primary)
        }

        .Evaluation .Address .data3 .myImages .btnImage{
            margin-left: 125px; 
            margin-top: 40px; 
        }
        
        .Evaluation .Address .data3 .myImages .featuredImage{
            margin-right: 125px; 
            margin-top: 40px; 
        }
        
        .Evaluation .Address .data3 span {
            text-align: center;
        }
        
        .Evaluation .Resume .data3 span {
            text-align: center;
        }
        
        
    }
    }
    }
    
}
