.TermsOfUse {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0px 0px 0px;
}

.TermsOfUse h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
    color: var(--Primary);
}
.TermsOfUse .text {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 40px;
}
.TermsOfUse .text h4 {
    font-weight: 700;
    margin: 15px 0 5px 0;
    color: var(--Primary)
}
.TermsOfUse .text p {
    font-weight: 500;
    margin: 2px 0;
    color: var(--Paragraph)
}