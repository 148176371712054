.Companies {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0px 0px 0px;
}

.Companies h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: bold;

}
