.ConfirmedAccount {
    width: 100%;
    height: 100vh;
    background-color: var(--BorderHover);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ConfirmedAccount h2 {
    text-align: center;
    color: var(--Primary);
    font-weight: 900;
    margin: 10px;
}
.ConfirmedAccount img {
    width: 350px;
    margin: 10px;
}

.ConfirmedAccount .coming {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 10px;
}
.ConfirmedAccount .coming h4 {
    text-align: center;
    color: var(--Gray);
    font-weight: 700;
    margin-bottom: 20px;
}
.ConfirmedAccount .coming h4 svg {
    margin-right: 3px;
    margin-bottom: -2px;
    margin-top: 0px;
}
.ConfirmedAccount .logo {
    width: 150px;
    margin: 20px;
}
.ConfirmedAccount .logo img {
    width: 100%;
}
.ConfirmedAccount .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.ConfirmedAccount .buttons a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-decoration: none;
    border: none;
    background-color: var(--Primary);
    padding: 10px 20px;
    border-radius: 6px;
    color: var(--White);
    margin: 5px;
    width: 200px;
    font-size: 14px;
}


@media (max-width: 600px) {

    .ConfirmedAccount h2 {
        font-size: 20px;
    }

    .ConfirmedAccount img {
        width: 300px;
        margin: 10px;
    }

    .ConfirmedAccount .image {
        width: 261px;
        height: 164px;
        margin-top: 63px;
        margin-left: 1px;
    }


    .ConfirmedAccount .coming {
        width: 200px;
    }
}